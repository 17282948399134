<template>
  <ComponentHeader></ComponentHeader>
  <router-view/>
  <ComponentFooter></ComponentFooter>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref } from "vue";
  import ComponentHeader from "@/components/component_header.vue";
  import ComponentFooter from "@/components/component_footer.vue";
  export default defineComponent({
    name: 'App',
    components: {
      ComponentHeader,
      ComponentFooter
    }
  })
</script>

<style lang="scss">
#app {
  min-width: 1200px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #03061D;
  font-family: 'my-self-font' !important;
}
body, p, div, span, li {
  margin: 0;
}


.demo-dropdown {
  border: none !important;
  border-radius: 12px !important;
  // background: rgba(54, 63, 94, 0.9) !important;
    background: #171A2F !important;
  .el-dropdown-menu {
    // background: rgba(54, 63, 94, 0.9) !important;
    background: #171A2F !important;
  }
  .el-dropdown-menu__item {
    color: rgba(137, 154, 169, 1);
    padding: 10px 20px;
    .img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      display: inline-block;
      
    }
    .flag {
      width: 20px;
      margin-left: 4px;
    }
  }
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background: #171A2F;
    color: rgba(65, 164, 255, 1);
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #171A2F;
    color: rgba(65, 164, 255, 1);
  }
}
.el-popper__arrow {
  display: none;
}
.el-dialog__body {
  padding-top: 0 !important;
}

.gradient_text {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%); 
}




.animateHide {
  visibility: hidden;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0,-100%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes amplify {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1.5s;
}
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1.5s;
}
.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1.5s;
}
.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1.5s;
}
.amplify {
  animation-name: amplify;
  animation-duration: 2s;
}
.delay100 {
  animation-delay: 100ms; 
}
.delay200 {
  animation-delay: 200ms; 
}
.delay300 {
  animation-delay: 300ms; 
}
.delay400 {
  animation-delay: 400ms; 
}
.delay500 {
  animation-delay: 500ms; 
}
.delay600 {
  animation-delay: 600ms; 
}
.delay700 {
  animation-delay: 700ms; 
}
.delay800 {
  animation-delay: 800ms; 
}
.delay900 {
  animation-delay: 900ms; 
}


.procedure_wiki {
  margin-top: 40px;
  border: 1px solid rgba(255, 255, 255, .3);
  padding: 24px 32px 32px 32px;
  backdrop-filter: blur(12.5px);
  box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
  background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
  .procedure_text1 {
    color: #38EED6;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
  }
  .procedure_text2 {
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    margin-top: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    word-wrap: break-word;
  }
  .procedure_text3 {
    font-size: 16px;
    color: #41A4FF;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    word-wrap: break-word;
    text-decoration: underline;
  }
  .procedure_text4 {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
    line-height: normal;
    word-wrap: break-word;
  }
  .procedure_img {
    width: 100%;
    margin-top: 16px;
  }
  .procedure_code {
    padding: 24px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    margin-top: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    background: #10132F;
    box-sizing: border-box;
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .procedure_list1 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .procedure_li {
      display: flex;
      margin-right: 60px;
      align-items: center;
      .procedure_li_icon {
        width: 12px;
        height: 12px;
        margin-right: 16px;
        background: url(./assets/home/cont5_icon.png) 100%/100% no-repeat;
      }
      p {
        font-size: 16px;
        color: #899AA9;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }
  }
  
  .procedure_list2 {
    display: flex;
    margin-top: 16px;
    .procedure_li_icon {
      width: 12px;
      height: 12px;
      margin-top: 7px;
      margin-right: 16px;
      background: url(./assets/home/cont5_icon.png) 100%/100% no-repeat;
    }
    p {
      flex: 1;
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

</style>
