<template>
  <div class="historyDBC" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('historyDBC.title') }}</div>
      <div class="timeLine">
        <div class="time_cont delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="time_blank"></div>
          <div class="time_num">
            <p>01</p>
            <div class="time_line"></div>
          </div>
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text1_1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text1_2') }}</div>
          </div>
        </div>
        <div class="time_cont text_right delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title2') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text2_1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text2_2') }}</div>
            <div class="time_cont_text">(<a href="https://github.com/DeepBrainChain">https://github.com/DeepBrainChain</a>)</div>
          </div>
          <div class="time_num">
            <p>02</p>
            <div class="time_line"></div>
          </div>
          <div class="time_blank"></div>
        </div>
        <div class="time_cont delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="time_blank"></div>
          <div class="time_num">
            <p>03</p>
            <div class="time_line"></div>
          </div>
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title3') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text3_1') }}</div>
          </div>
        </div>
        <div class="time_cont text_right delay400 animateHide" v-animate="{delay: 400, class:'fadeInUp'}">
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title4') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_1') }}</div>
            <div class="time_cont_text">(<a href="https://www.dbcwallet.io">https://www.dbcwallet.io</a>)</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_2') }}</div>
            <div class="time_cont_text">(<a href="https://dbc.subscan.io">https://dbc.subscan.io</a>)</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_3') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_4') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_5') }}</div>
            <div class="time_cont_text">(<a href="https://galaxyrace.deepbrainchain.org">https://galaxyrace.deepbrainchain.org</a>)</div>
            <div class="time_cont_text">{{ $t('historyDBC.text4_6') }}</div>
          </div>
          <div class="time_num">
            <p>04</p>
            <div class="time_line"></div>
          </div>
          <div class="time_blank"></div>
        </div>
        <div class="time_cont delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
          <div class="time_blank"></div>
          <div class="time_num">
            <p>05</p>
            <div class="time_line"></div>
          </div>
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title5') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text5_1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text5_2') }}</div>
            <div class="time_cont_text">(<a href="https://www.haibaogpu.com">https://www.haibaogpu.com</a>)</div>
            <div class="time_cont_text">{{ $t('historyDBC.text5_3') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text5_4') }}</div>
            <div class="time_cont_text">(<a href="https://www.hycons.cloud">https://www.hycons.cloud</a>)</div>
            <div class="time_cont_text">{{ $t('historyDBC.text5_5') }}</div>
            <div class="time_cont_text">(<a href="https://www.deeplink.cloud">https://www.deeplink.cloud</a>)</div>
          </div>
        </div>
        <div class="time_cont text_right delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title6') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text6_1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text6_2') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text6_3') }}</div>
          </div>
          <div class="time_num">
            <p>06</p>
            <div class="time_line"></div>
          </div>
          <div class="time_blank"></div>
        </div>
        <div class="time_cont  delay700 animateHide" v-animate="{delay: 700, class:'fadeInUp'}">
          <div class="time_blank"></div>
          <div class="time_num">
            <p>07</p>
            <div class="time_line"></div>
          </div>
          <div class="time_content">
            <div class="time_cont_title">{{ $t('historyDBC.title7') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_1') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_2') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_3') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_4') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_5') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_6') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_7') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_8') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_9') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_10') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_11') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_12') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_13') }}</div>
            <div class="time_cont_text">{{ $t('historyDBC.text7_14') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="contbg"><ComponentWave></ComponentWave></div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import ComponentWave from "@/components/component_wave.vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'historyDBC',
    components: {
      ComponentWave
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.historyDBC {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1000px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .timeLine {
      width: 100%;
      // height: 1000px;
      margin: 40px 0;
      overflow: hidden;
      .time_cont {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        &.text_right {
          text-align: right;
        }
        .time_blank {
          width: 536px;
        }
        .time_content {
          width: 536px;
          display: flex;
          line-height: 150%;
          flex-direction: column;
          .time_cont_title {
            color: #fff;
            font-size: 24px;
            font-weight: 700;
            margin-top: 18px;
          }
          .time_cont_text {
            font-size: 16px;
            color: rgba(255, 255, 255, .7);
            font-weight: 500;
            margin-top: 10px;
            a {
              color: #fff;
            }
          }
        }
        .time_num {
          width: 80px;
          display: flex;
          align-items: center;
          flex-direction: column;
          p {
            color: rgba(255, 255, 255, .8);
            font-size: 40px;
            font-weight: 900;
            line-height: 150%; 
          }
          .time_line {
            width: 2px;
            margin-top: 5px;
            height: -webkit-fill-available;
            background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
      }
    }
  }
  .content3 {
    position: relative;
    width: 100%;
    padding: 100px 0 160px;
    .contbg {
      position: absolute;
      margin: auto;
      bottom: 0;
      width: 1900px;
      height: 430px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1900px;
        height: 430px;
      }
    }
  }
}

</style>
