
<template>
  <div class="introDBC" :class="{lan_En : (lan != 'zh' && lan != 'ja' && lan != 'ko')}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="bg2"><img src="../../assets/home/cont4_bg2.jpg" alt=""></div>
    <div class="bg3"><img src="../../assets/home/cont4_bg3.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('introDBC.title') }}</div>
      <div class="cont_desc">{{ $t('introDBC.text1') }}</div>
      <div class="cont_desc">{{ $t('introDBC.text2') }}</div>
    </div>
    <div class="content2">
      <div class="council delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="council_title">{{ $t('introDBC.cont2.block1.title') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text1') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text2') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block1.text3') }}</div>
      </div>
      <div class="council delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
        <div class="council_title">{{ $t('introDBC.cont2.block2.title') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text1') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text2') }}</div>
        <div class="council_text">{{ $t('introDBC.cont2.block2.text3') }}</div>
        <!-- <div class="council_btn">{{ $t('introDBC.cont2.block2.btn') }}</div> -->
        <a class="council_btn" target="_blank" href="https://www.dbcwallet.io/#/council">{{ $t('introDBC.cont2.block2.btn') }}</a>
      </div>
    </div>
    <div class="content3">
      <div class="cont3_text1">{{ $t('introDBC.cont3.title') }}</div>
      <div class="useList">
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img1.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text1') }}<br/>{{ $t('introDBC.cont3.text1_2') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img2.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text2') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text2_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img3.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text3') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text3_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img4.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text4') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text4_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img5.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text5') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text5_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img6.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text6') }}</p>
          <p class="use_text">{{ $t('introDBC.cont3.text6_1') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img7.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text7') }}</p>
        </div>
        <div class="useli">
          <div class="use_iocn"><img src="../../assets/learn/use_img8.png" alt=""></div>
          <p class="use_title">{{ $t('introDBC.cont3.text8') }}</p>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="cont4_text1">{{ $t('introDBC.cont4.title') }}</div>
      <div class="memberList">
        <div class="member" v-for="(el, index) in top3Array">
          <div class="member_img"><img :src="el.img" alt=""></div>
          <div class="member_name gradient_text">{{ el.member_name }}</div>
          <div class="member_job">{{ $t('introDBC.cont4.job') }}</div>
          <div class="member_num">{{ $t('introDBC.cont4.reward') }}{{ el.stake_amount }}</div>
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="cont5_text1">{{ $t('introDBC.cont5.title') }} {{ countDown.days }} {{ $t('introDBC.cont5.text11') }} {{ countDown.hours }} {{ $t('introDBC.cont5.text7') }}</div>
      <div class="electList">
        <div class="electLi" v-for="(el, index) in proposalData" :key="index" @click="linkHerf(el.link)">
          <div class="vote_num">
            <p class="vote">{{ $t('introDBC.cont5.text1') }}{{el.id}}</p>
            <div class="time" :class="{active: el.status == 'success', active2: el.status == 'fail'}">{{ el.status == 'success' ? $t('introDBC.cont5.text3') : (el.status == 'fail' ? $t('introDBC.cont5.text3_1') : $t('introDBC.cont5.text2')) }}</div>
          </div>
          <p class="elect_title gradient_text">{{ $t('introDBC.cont5.text4') }}{{el.price.toLocaleString()}}DBC</p>
          <p class="elect_text1">{{ $t('introDBC.cont5.text5') }}{{el.name}}</p>
          <p class="elect_text2">{{el.content}}</p>
          <!-- <p class="elect_text3 gradient_text">{{ $t('introDBC.cont5.text6') }}xxx{{ $t('introDBC.cont5.text7') }}</p> -->
          <div class="praise">
            <div class="favour"><div class="praise_icon"><img src="../../assets/learn/good.png" alt=""></div>{{ $t('introDBC.cont5.text9') }}{{el.agree}}</div>
            <div class="against"><div class="praise_icon"><img src="../../assets/learn/bad.png" alt=""></div>{{ $t('introDBC.cont5.text10') }}{{el.oppose}}</div>
          </div>
        </div>
      </div>
      <a target="_blank" class="cont_btn" href="https://www.reddit.com/r/DBC_Council/">{{ $t('introDBC.seemore') }}</a>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { useStore } from "vuex"
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { getMembers, getMemberName, getBlockNumber } from '../../api/query'
  import Member1 from '../../assets/learn/member_img1.svg'
  import Member2 from '../../assets/learn/member_img2.svg'
  import Member3 from '../../assets/learn/member_img3.svg'
  export default defineComponent({
    name: 'introDBC',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      // watch(lan, (newvlaue, oldvalue) => {
        
      // })
      const proposalData = ref([
        {
          id: 188,
          name: 'Cherry',
          wallet: "5HTWQk6JXDCZ5d6QDu8Cfobyy1TbJLWczohqJhhFvWhZdrEn",
          price: 3000000,
          content: 'GM VIETNAM越南大会',
          endTime: '',
          status: 'afoot', //success fail afoot
          agree: 1,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1csqqi2/proposal_115motion_188gm_vietnam%E8%B6%8A%E5%8D%97%E5%A4%A7%E4%BC%9A/'
        },
        {
          id: 187,
          name: 'HYCONS',
          wallet: "5DUZdEAoCNCoAQ62u3SLADj1ckJZzQ8X4CtWmcw1YXDoSHNd",
          price: 5000000,
          content: '申请拨款开发云游戏体验空间和软件，以体验空间和软件拓展DBC生态系统',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1cqo34l/proposal_114motions_187/'
        },
        {
          id: 186,
          name: 'TianyuCloud',
          wallet: "5EvqTox3q8podecD6yvhGAGVHaoKECBXPqNmhduafP3ud9bF",
          price: 5000000,
          content: '关于四川云网吧&云电竞业务落地补贴的申请提案',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1col9n4/%E5%85%B3%E4%BA%8E%E5%9B%9B%E5%B7%9D%E4%BA%91%E7%BD%91%E5%90%A7%E4%BA%91%E7%94%B5%E7%AB%9E%E4%B8%9A%E5%8A%A1%E8%90%BD%E5%9C%B0%E8%A1%A5%E8%B4%B4%E7%9A%84%E7%94%B3%E8%AF%B7%E6%8F%90%E6%A1%88/'
        },
        {
          id: 185,
          name: 'LIXIAOJIE',
          wallet: "5FYvCrUcbnUkUpRKWXUo1NmUiw3wftEcQBAQcdg8w8x4AWaG",
          price: 5000000,
          content: 'Proposal 111，motions 185 郑州爱电竞合作事宜补贴提案、Proposal 111，motions 185 Subsidy Proposal for the Zhengzhou Love Gaming Cooperation Matters',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1cnzejn/proposal_111motions_185_%E9%83%91%E5%B7%9E%E7%88%B1%E7%94%B5%E7%AB%9E%E5%90%88%E4%BD%9C%E4%BA%8B%E5%AE%9C%E8%A1%A5%E8%B4%B4%E6%8F%90%E6%A1%88proposal/'
        },
        {
          id: 184,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 15500000,
          content: '2024 BLOCKCHAIN EXPO 东京大会，2024 BLOCKCHAIN EXPO TOKYO Proposal_submitted onchain',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1cn67ct/proposal_110motions_1842024_blockchain_expo/'
        },
        {
          id: 183,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 800000,
          content: 'TG管理AI Mamun第二季度志愿者工作津贴',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1clhvyk/%E5%85%B3%E4%BA%8Etg%E5%BF%97%E6%84%BF%E8%80%85%E7%AC%AC%E4%BA%8C%E5%AD%A3%E5%BA%A6%E6%B4%A5%E8%B4%B4%E7%9A%84%E5%9B%BD%E5%BA%93%E7%94%B3%E8%AF%B7%E6%8F%90%E6%A1%88motions_183/'
        },
        // {
        //   id: 182,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 0,
        //   content: 'DBC Runtime Update',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 14,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/1befwj5/dbc_runtime_update_274/'
        // },
        {
          id: 181,
          name: 'Cherry',
          wallet: "5HTWQk6JXDCZ5d6QDu8Cfobyy1TbJLWczohqJhhFvWhZdrEn",
          price: 440000,
          content: 'DePIN中国行提案，Proposal for DePIN China Tour',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1bsr4cf/proposal_108_motion_181depin%E4%B8%AD%E5%9B%BD%E8%A1%8C%E6%8F%90%E6%A1%88proposal_for/'
        },
        {
          id: 180,
          name: 'Cherry',
          wallet: "5HTWQk6JXDCZ5d6QDu8Cfobyy1TbJLWczohqJhhFvWhZdrEn",
          price: 3000000,
          content: '2024DePIN全球硬件大会，2024DePIN Global Hardware Summit',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1bqj4qt/proposal_107motions_1802024depin%E5%85%A8%E7%90%83%E7%A1%AC%E4%BB%B6%E5%A4%A7%E4%BC%9A2024depin/'
        },
        {
          id: 179,
          name: 'Cherry',
          wallet: "5HTWQk6JXDCZ5d6QDu8Cfobyy1TbJLWczohqJhhFvWhZdrEn",
          price: 7400000,
          content: '美国共识大会以及DBC硅谷开发者会议提案，US Consensus and Silicon Valley DBC Developer Conference proposal',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1bo0wz0/proposal_106_motion_179_%E7%BE%8E%E5%9B%BD%E5%85%B1%E8%AF%86%E5%A4%A7%E4%BC%9A%E4%BB%A5%E5%8F%8Adbc%E7%A1%85%E8%B0%B7%E5%BC%80%E5%8F%91%E8%80%85%E4%BC%9A%E8%AE%AE%E6%8F%90%E6%A1%88us/'
        },
        {
          id: 176,
          name: 'DBC-KOREA',
          wallet: "5FHJYkMKxr87G3r914ME1DEE1mxpHeVzQ3j8pd1sy4zSFJSP",
          price: 7500000,
          content: 'Apply for funding to promote Deep Brain Chain in English and Chinese for the month of March.',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1b8nvmt/proposal_id_105_motions_176_apply_for_funding_to/'
        },
        {
          id: 175,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 2240000,
          content: '关于申请2024年度Subscan浏览器运维费用的提案',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1b28wwe/104motion175%E5%85%B3%E4%BA%8E%E7%94%B3%E8%AF%B72024%E5%B9%B4%E5%BA%A6subscan%E6%B5%8F%E8%A7%88%E5%99%A8%E8%BF%90%E7%BB%B4%E8%B4%B9%E7%94%A8%E7%9A%84%E6%8F%90%E6%A1%88/'
        },
        {
          id: 174,
          name: 'DBC-YES',
          wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
          price: 5370000,
          content: '关于主链系列节点升级造成的惩罚补偿',
          endTime: '',
          status: 'success', //success fail afoot
          agree: 13,
          oppose: 0,
          link: 'https://www.reddit.com/r/DBC_Council/comments/1b28ls1/103motion174%E5%85%B3%E4%BA%8E%E4%B8%BB%E9%93%BE%E7%B3%BB%E5%88%97%E8%8A%82%E7%82%B9%E5%8D%87%E7%BA%A7%E9%80%A0%E6%88%90%E7%9A%84%E6%83%A9%E7%BD%9A%E8%A1%A5%E5%81%BF/'
        }
        // {
        //   id: 166,
        //   name: 'DBC-KOREA',
        //   wallet: "5FHJYkMKxr87G3r914ME1DEE1mxpHeVzQ3j8pd1sy4zSFJSP",
        //   price: 15500000,
        //   content: '申请DBC参加迪拜TOKEN2049活动， 展位参与资金（DBC理事会）【Proposal id 96 ; Motions 166 】Application for DBC to participate in TOKEN2049 in Dubai, Booth Participation Funding (DBC Board of Directors)',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 13,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/1afzi3q/proposal_id_96_motions_166_%E7%94%B3%E8%AF%B7dbc%E5%8F%82%E5%8A%A0%E8%BF%AA%E6%8B%9Ctoken2049%E6%B4%BB%E5%8A%A8/'
        // },
        // {
        //   id: 165,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 1455000,
        //   content: 'TG管理AIMAMUM未来三个月奖励津贴',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 13,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/1afadfc/95motion165tg%E7%AE%A1%E7%90%86aimamum%E6%9C%AA%E6%9D%A5%E4%B8%89%E4%B8%AA%E6%9C%88%E5%A5%96%E5%8A%B1%E6%B4%A5%E8%B4%B4/'
        // },
        // {
        //   id: 164,
        //   name: 'TESS',
        //   wallet: "5EqvAsoRsTo8VNnB4Poqf2rf1TBDk9WwQxaxr8FAyVTKMVfA",
        //   price: 90000,
        //   content: 'Twitter applies for blue check',
        //   endTime: '',
        //   status: 'fail', //success fail afoot
        //   agree: 4,
        //   oppose: 0,
        //   link: ''
        // },
        // {
        //   id: 163,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 7000000,
        //   content: '#94，Motion163，参与泰国B2GC峰会宣传经费',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 11,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/18spuc6/94motion163%E5%8F%82%E4%B8%8E%E6%B3%B0%E5%9B%BDb2gc%E5%B3%B0%E4%BC%9A%E5%AE%A3%E4%BC%A0%E7%BB%8F%E8%B4%B9/'
        // },
        // {
        //   id: 162,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 1200000,
        //   content: '关于DBC网站重新设计所需经费的申请提案',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 7,
        //   oppose: 1,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/18nq814/93motion163%E5%85%B3%E4%BA%8Edbc%E7%BD%91%E7%AB%99%E9%87%8D%E6%96%B0%E8%AE%BE%E8%AE%A1%E6%89%80%E9%9C%80%E7%BB%8F%E8%B4%B9%E7%9A%84%E7%94%B3%E8%AF%B7%E6%8F%90%E6%A1%88/'
        // },
        // {
        //   id: 161,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 5000000,
        //   content: '关于支持多国测试机器的国会资金提案',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 8,
        //   oppose: 1,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/18drvkn/92motion162%E5%85%B3%E4%BA%8E%E6%94%AF%E6%8C%81%E5%A4%9A%E5%9B%BD%E6%B5%8B%E8%AF%95%E6%9C%BA%E5%99%A8%E7%9A%84%E5%9B%BD%E4%BC%9A%E8%B5%84%E9%87%91%E6%8F%90%E6%A1%88/'
        // },
        // {
        //   id: 160,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 10000000,
        //   content: '中国区第一家云网吧落地算工上卡激励',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 13,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/17wqe1o/%E6%8F%90%E6%A1%88id91%E4%B8%AD%E5%9B%BD%E5%8C%BA%E7%AC%AC%E4%B8%80%E5%AE%B6%E4%BA%91%E7%BD%91%E5%90%A7%E8%90%BD%E5%9C%B0%E7%AE%97%E5%B7%A5%E4%B8%8A%E5%8D%A1%E6%BF%80%E5%8A%B1/'
        // },
        // {
        //   id: 157,
        //   name: 'TianyuCloud',
        //   wallet: "5EvqTox3q8podecD6yvhGAGVHaoKECBXPqNmhduafP3ud9bF",
        //   price: 135000,
        //   content: '制作DBC&DeepLink 介绍书',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 8,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/17b9zfz/funding_proposal_89/'
        // },
        // {
        //   id: 156,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 3630000,
        //   content: '半年度Huobi&Bitmart交易流动性管理费申请',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 12,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/17besmp/%E6%8F%90%E6%A1%88%E5%8F%B790motion_156%E5%8D%8A%E5%B9%B4%E5%BA%A6huobibitmart%E4%BA%A4%E6%98%93%E6%B5%81%E5%8A%A8%E6%80%A7%E7%AE%A1%E7%90%86%E8%B4%B9%E7%94%B3%E8%AF%B7/'
        // },
        // {
        //   id: 155,
        //   name: 'DBC-YES',
        //   wallet: "5DvbnMqAaHNJ7bxY6htWoAHcUG4hasqmHFeSfPXkApx5ywoR",
        //   price: 12800,
        //   content: 'Promote deepbrainchain in communities in Indonesian such as companies, crypto communities.',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 12,
        //   oppose: 0,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/16lmuhj/proposal_88_fundingproposal/'
        // },
        // {
        //   id: 154,
        //   name: 'TianyuCloud',
        //   wallet: "5EvqTox3q8podecD6yvhGAGVHaoKECBXPqNmhduafP3ud9bF",
        //   price: 4000000,
        //   content: 'Applying for the fund subsidy for DBC Cloud Internet Cafe andCloud E-sports Domestic market expansion (TianyuCloud)',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 9,
        //   oppose: 3,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/16i9xbv/proposal_id_87_motions_154_applying_for_the_fund/'
        // },
        // {
        //   id: 153,
        //   name: 'DBC-KOREA',
        //   wallet: "5FHJYkMKxr87G3r914ME1DEE1mxpHeVzQ3j8pd1sy4zSFJSP",
        //   price: 1100000,
        //   content: '代表社区申请基于DBC & DEEPLINK云网吧/云电竞酒店方案中，【瓦洛兰特】游戏鼠标问题找专业人外发开发解决费用',
        //   endTime: '',
        //   status: 'success', //success fail afoot
        //   agree: 11,
        //   oppose: 2,
        //   link: 'https://www.reddit.com/r/DBC_Council/comments/16hb06x/proposal_id_85_motions_152%E4%BB%A3%E8%A1%A8%E7%A4%BE%E5%8C%BA%E7%94%B3%E8%AF%B7%E5%9F%BA%E4%BA%8Edbc/'
        // }
      ])
      const linkHerf = (str) => {
        if (str) {
          window.open(str)
        }
      }
      const top3Array = ref([])
      const getMembwe = async () => {
        const memberData = await getMembers()
        memberData.sort((a, b) => {
          return Number(b.stake) - Number(a.stake)
        })
        const topimg = {
          mem0: Member1,
          mem1: Member2,
          mem2: Member3
        }
        // const top3Data = memberData.slice(0,3)
        // for (let i = 0; i< top3Data.length; i++) {
        //   top3Data[i].member_name = await getMemberName(el.who)
        //   top3Data[i].img = topimg[`mem${i}`]
        // }
        memberData.slice(0,3).map(async (el, index) => {
          el.member_name = await getMemberName(el.who)
          el.img = topimg[`mem${index}`]
          top3Array.value.push(el)
        })
      }
      const countDown = ref({
        days: 0,
        hours: 0
      })
      const getTime = async () => {
        const block_num = await getBlockNumber()
        const cycle = 120 * 2880;
        let cycle_now = 1
        for (let i = 1; i < 100; i ++ ) {
          if (cycle * i - block_num > 0) {
            cycle_now = cycle * (i-1)
            break;
          }
        }
        const use_time = 120 - ((block_num - cycle_now) / 2880)
        countDown.value.days = parseInt(use_time)
        countDown.value.hours = (parseInt((parseFloat(use_time) % 1) * 24)).toString().padStart(2, '0');
      }
      onMounted(() => {
        getMembwe()
        getTime()
      });
      return {
        lan,
        linkHerf,
        top3Array,
        proposalData,
        countDown
      };
    }
  })
</script>

<style lang="scss" scoped>
.introDBC {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .bg2 {
    position: absolute;
    top: 1260px;
    margin: auto;
    width: 1200px;
    height: 1200px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1200px;
      height: 1200px;
    }
  }
  .bg3 {
    position: absolute;
    top: 1610px;
    margin: auto;
    width: 1603px;
    height: 1348px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1603px;
      height: 1348px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1200px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .video {
      width: 800px;
      height: 450px;
      margin: auto;
      background: #000;
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    padding: 80px 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    .council {
      width: 588px;
      height: 470px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid;
      background: rgba(16, 19, 47, 1);
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      .council_title {
        color: #fff;
        font-size: 48px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .council_text {
        font-size: 16px;
        font-weight: 400;
        color: #899AA9;
        margin-top: 16px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .council_btn {
        display: inline-flex;
        width: 320px;
        height: 48px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin-top: 24px;
        line-height: normal;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        border: 1px solid #FFF;
      }
    }
  }
  .content3 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 80px 0;
    box-sizing: border-box;
    .cont3_text1 {
      width: 100%;
      color: #fff;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .useList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .useli {
        width: 282px;
        padding: 32px;
        display: flex;
        margin-top: 40px;
        box-sizing: border-box;
        align-items: center;
        flex-direction: column;
        border: 2px solid #FFF;
        backdrop-filter: blur(12.5px);
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        &:hover {
          border: 2px solid;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          .use_title {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%); 
          }
        }
        .use_iocn {
          width: 48px;
          height: 48px;
          margin-bottom: 24px;
        }
        .use_title {
          color: #fff;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-bottom: 8px;
        }
        .use_text {
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .content4 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 80px 0;
    box-sizing: border-box;
    .cont4_text1 {
      width: 100%;
      color: #fff;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .memberList {
      width: 100%;
      display: flex;
      padding: 24px 0;
      margin-top: 40px;
      justify-content: space-between;
      .member {
        width: 370px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .member_img {
          width: 160px;
          height: 160px;
          display: flex;
          overflow: hidden;
          align-items: center;
          margin-bottom: 24px;
          border-radius: 160px;
          justify-content: center;
          background: url(../../assets/learn/use_bg.png) 100%/100% no-repeat;
          img {
            width: 152px;
            height: 152px;
            border-radius: 152px;
          }
        }
        .member_name {
          font-size: 24px;
          font-weight: 400;
          // line-height: 24px;
        }
        .member_job {
          margin: 8px 0;
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
        }
        .member_num {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
  .content5 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 80px 0;
    box-sizing: border-box;
    flex-direction: column;
    .cont5_text1 {
      width: 100%;
      color: #fff;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .electList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // margin-top: 40px;
      justify-content: space-between;
      .electLi {
        width: 282px;
        padding: 32px;
        cursor: pointer;
        margin-top: 40px;
        box-sizing: border-box;
        backdrop-filter: blur(8px);
        background: rgba(65, 164, 255, 0.16);
        &:hover {
          background: rgba(65, 164, 255, 0.32);
        }
        .vote_num {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .vote {
            font-size: 14px;
            color: #899AA9;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.28px;
          }
          .time {
            color: #fff;
            font-size: 14px;
            padding: 4px 8px;
            font-weight: 500;
            line-height: normal;
            background: #38EED6;
            letter-spacing: 0.28px;
            &.active {
              background: #41A4FF;
            }
            &.active2 {
              background: #6c6c6c;
            }
          }
        }
        .elect_title {
          font-size: 24px;
          font-weight: 500;
          margin-top: 24px;
          line-height: normal;
          letter-spacing: 0.48px;
        }
        .elect_text1 {
          color: #fff;
          font-size: 16px;
          margin-top: 8px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }
        .elect_text2 {
          height: 72px;
          font-size: 14px;
          margin-top: 8px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.28px;
          overflow: hidden; /* 超出部分将被隐藏 */
          display: -webkit-box; /* 必需的WebKit前缀 */
          -webkit-line-clamp: 3; /* 限制显示的行数为2行 */
          -webkit-box-orient: vertical; /* 垂直布局 */
        }
        .elect_text3 {
          font-size: 16px;
          font-weight: 400;
          margin-top: 24px;
          line-height: normal;
          letter-spacing: 0.32px;
        }
        .praise {
          width: 100%;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          margin-top: 24px;
          line-height: normal;
          align-items: center;
          letter-spacing: 0.32px;
          justify-content: space-between;
          .favour {
            display: flex;
            color: #EDA439;
            align-items: center;
          }
          .against {
            display: flex;
            color: #899AA9;
            align-items: center;
          }
          .praise_icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
        border: 1px solid #FFF;
      }
    }
  }
}

.lan_En.introDBC {
  .content2 {
    .council {
      .council_title {
        font-size: 30px;
      }
      .council_text {
        font-size: 13px;
      }
    }
  }
  .content3 {
    .useList {
      .useli{
        .use_title {
          font-size: 16px;
        }
        .use_text {
          font-size: 14px;
        }
      }
    } 
  }
  .content5 {
    .cont5_text1 {
      font-size: 34px;
    }
    .electList {
      .electLi {
        .vote_num {
          .vote {
            font-size: 12px;
          }
          .time {
            font-size: 12px;
          }
        }
        .elect_title {
          font-size: 18px;
        }
        .elect_text1 {
          font-size: 14px;
        }
        .elect_text2 {
          font-size: 12px;
        }
        .praise {
          font-size: 14px;
        }
      }
    }
  }
}

</style>
