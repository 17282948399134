<template>
  <div class="footer" :class="{lan_En : lan != 'zh'}">
    <div class="content">
      <div class="leftContent">
        <img class="logo" src="../assets/logo.png" alt="">
        <div class="community">
          <!-- <div class="community_li"><div class="icon icon1"></div></div> -->
          <a target="_blank" href="https://twitter.com/DeepBrainChain" class="community_li" ><div class="icon icon2"></div></a>
          <!-- <div class="community_li"><div class="icon icon3"></div></div> -->
          <a target="_blank" href="https://www.youtube.com/@deepbrainchain7707" class="community_li"><div class="icon icon4"></div></a>
          <a target="_blank" href="https://www.reddit.com/r/DBC_Council/" class="community_li"><div class="icon icon5"></div></a>
          <a target="_blank" href="https://t.me/deepbrainchainglobal" class="community_li"><div class="icon icon6"></div></a>
        </div>
        <div class="downloadLogo">
          <div class="down_title">{{$t('footer.logo')}}</div>
          <a download="DeepBrainChainLogo.png" href="/DeepBrainChainLogo.png" class="down_text">DeepBrain Chain Logo</a>
        </div>
        <div class="serverContent">
          <div class="server_title">{{$t('footer.server')}}</div>
          <a href="mailto:service@deepbrainchain.org" class="server_text">service@deepbrainchain.org</a>
        </div>
      </div>
      <div class="rightContent">
        <div class="list">
          <p class="title">{{$t('meau.nav1')}}</p>
          <p class="text" @click="linkHref2('/buyGpuServer')">{{$t('meau.nav1_1')}}</p>
          <p class="text" @click="linkHref2('/getReward')">{{$t('meau.nav1_2')}}</p>
          <p class="text" @click="linkHref2('/voteReward')">{{$t('meau.nav1_3')}}</p>
          <p class="text" @click="linkHref2('/gpuMiner')">{{$t('meau.nav1_4')}}</p>
          <p class="text" @click="linkHref2('/applyCouncil')">{{$t('meau.nav1_5')}}</p>
          <p class="text" @click="linkHref2('/councilVoting')">{{$t('meau.nav1_6')}}</p>
          <p class="text" @click="linkHref2('/referenda')">{{$t('meau.nav1_7')}}</p>
        </div>
        <div class="list">
          <p class="title">{{$t('meau.nav2')}}</p>
          <p class="text" @click="linkHref2('/learnDBC')">{{$t('meau.nav2_1')}}</p>
          <p class="text" @click="linkHref2('/whatisGPU')">{{$t('meau.nav2_2')}}</p>
          <p class="text" @click="linkHref2('/historyDBC')">{{$t('meau.nav2_3')}}</p>
          <p class="text" @click="linkHref2('/Whitepaper')">{{$t('meau.nav2_4')}}</p>
          <p class="text" @click="linkHref2('/introDBC')">{{$t('meau.nav2_5')}}</p>
          <p class="text" @click="linkHref2('/aboutTeam')">{{$t('meau.nav2_6')}}</p>
        </div>
        <div class="list">
          <p class="title">{{$t('meau.nav3')}}</p>
          <p class="text" @click="linkHref2('/deployCloud')">{{$t('meau.nav3_1')}}</p>
          <p class="text" @click="linkHref2('/cloudInternet')">{{$t('meau.nav3_2')}}</p>
          <p class="text" @click="linkHref2('/gameAgents')">{{$t('meau.nav3_3')}}</p>
          <p class="text" @click="linkHref2('/convertGame')">{{$t('meau.nav3_4')}}</p>
          <p class="text" @click="linkHref2('/applyForFunding')">{{$t('meau.nav3_5')}}</p>
        </div>
        <div class="list">
          <p class="title">{{$t('meau.nav4')}}</p>
          <p class="text" @click="linkHref2('/createwallet')">{{$t('meau.nav4_1')}}</p>
          <p class="text" @click="linkHref2('/polkaWallet')">{{$t('meau.nav4_2')}}</p>
          <!-- <p class="text" @click="linkHref2('/ERC20Wallet')">{{$t('meau.nav4_3')}}</p> -->
          <p class="text" @click="linkHref2('/mathWallet')">{{$t('meau.nav4_4')}}</p>
          <p class="text" @click="linkHref2('/ImToken')">{{$t('meau.nav4_5')}}</p>
          <p class="text" @click="linkHref2('/TrustWallet')">{{$t('meau.nav4_6')}}</p>
          <p class="text" @click="linkHref2('/metaMask')">{{$t('meau.nav4_7')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted, computed } from "vue";
  import { useI18n } from "vue-i18n";
  import { useRouter } from 'vue-router'
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'ComponentFooter',
    setup() {
      const router = useRouter()
      // const lan = ref(localStorage.getItem('lan') || 'en')
      const store = useStore()
      let lan = computed(() => store.state.lan)
      const href = computed(() => store.state.path )
      const { locale } = useI18n()
      const ishover = ref(false)
      const handleCommand = (command) => {
        lan.value = command
        locale.value = lan.value
        localStorage.setItem('lan', command)
      }
      const change = (el) => {
        ishover.value = el
      }
      const linkHref = (el) => {
        router.push({path: el})
      }
      const isPc = () => {
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
      }
      const linkHref2 = (command) => {
        if (command == '/Whitepaper') {
          if (lan.value == 'zh') {
            window.open('/DeepBrainChainWhitepaper_zh.pdf');
          } else {
            window.open('/DeepBrainChainWhitepaper_en.pdf');
          }
          return
        }
        router.push({path: command})
        href.value = command
        localStorage.setItem('path', command)
        store.commit('changePath', command)
      }
      const isphone = ref(false)
      onMounted(() => {
        isphone.value = isPc()
      });
      return {
        lan,
        href,
        ishover,
        isphone,
        handleCommand,
        change,
        linkHref,
        linkHref2,
      };
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 360px;
  font-style: normal;
  // font-family: "Noto Sans SC";
  background: rgba(0, 0, 0, 0.40);
  .content {
    width: 1200px;
    height: 360px;
    margin: auto;
    display: flex;
    padding: 64px 0 0;
    box-sizing: border-box;
    justify-content: space-between;
    .leftContent {
      width: 217px;
      margin-right: 110px;
      .logo {
        width: 217px;
        height: 28px;
        margin-bottom: 24px;
      }
      .community {
        display: flex;
        align-items: center;
        // justify-content: space-around;
        .community_li {
          width: 50px;
          display: flex;
          margin-right: 10px;
          align-items: center;
          // background: #848895;
          justify-content: center;
          .icon {
            width: 50px;
            height: 50px;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.2);
            }
          }
          .icon1 {
            background: url(../assets/footer/icon1.png) 100%/100% no-repeat;
          }
          .icon2 {
            background: url(../assets/footer/x.svg) 100%/100% no-repeat;
          }
          .icon3 {
            background: url(../assets/footer/icon3.png) 100%/100% no-repeat;
          }
          .icon4 {
            // background: url(../assets/footer/icon4.png) 100%/100% no-repeat;
            background: url(../assets/footer/youtube.svg) 100%/100% no-repeat;
          }
          .icon5 {
            // background: url(../assets/footer/icon5.png) 100%/100% no-repeat;
            background: url(../assets/footer/reddit.svg) 100%/100% no-repeat;
          }
          .icon6 {
            // background: url(../assets/footer/icon6.png) 100%/100% no-repeat;
            background: url(../assets/footer/tg.svg) 100%/100% no-repeat;
          }
        }
      }
      .downloadLogo {
        font-weight: 400;
        .down_title {
          color: #FFF;
          font-size: 16px;
          margin: 14px 0 10px;
        }
        .down_text {
          cursor: pointer;
          font-size: 14px;
          color: #899AA9;
          text-decoration: none;
          &:hover {
            color: #fff;
          }
        }
      }
      .serverContent {
        font-weight: 400;
        .server_title {
          color: #FFF;
          font-size: 16px;
          margin: 24px 0 10px;
        }
        .server_text {
          cursor: pointer;
          font-size: 14px;
          color: #899AA9;
          text-decoration: none;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .rightContent {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .list {
        font-weight: 400;
        line-height: 28px;
        .title {
          color: #FFF;
          font-size: 16px;
          text-transform: uppercase;
        }
        .text {
          cursor: pointer;
          color: #899AA9;
          font-size: 14px;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.lan_En.footer {
  .content {
    .leftContent {
      margin-right: 40px;
      .downloadLogo {
        .down_title {
          font-size: 14px;
        }
        .down_text {
          font-size: 12px;
        }
      }
      .serverContent {
        .server_title {
          font-size: 14px;
        }
        .server_text {
          font-size: 12px;
        }
      }
    }
    .rightContent {
      .list {
        .title {
          font-size: 14px;
        }
        .text {
          font-size: 12px;
        }
      }
    }
  }
}

</style>