export default {
  meau: {
    nav: '首页',
    nav1: '使用DBC',
    nav1_1: '购买GPU云服务',
    nav1_2: '部署POS节点获得奖励',
    nav1_3: '给POS节点投票',
    nav1_4: '成为GPU矿工获得奖励',
    nav1_5: '申请成为理事会成员',
    nav1_6: '给理事候选人投票',
    nav1_7: '参与公投',
    nav2: '学习',
    nav2_1: '什么是DeepBrainChain?',
    nav2_2: '什么是GPU',
    nav2_3: 'DeepBrainChain发展历程',
    nav2_4: '白皮书',
    nav2_5: 'DBC DAO自治 简介',
    nav2_6: '关于团队',
    nav3: '开发者',
    nav3_1: '租用GPU的Powerful API',
    nav3_2: '如何成为云网吧品牌商',
    nav3_3: '如何成为云游戏一体机代理商',
    nav3_4: '如何将AAA游戏转化为云游戏',
    nav3_5: '如何申请理事会国库资金',
    nav4: '钱包',
    nav4_1: 'DBCWallet',
    nav4_2: 'PolkaWallet',
    nav4_3: 'ERC20 Wallet',
    nav4_4: 'MathWallet',
    nav4_5: 'ImToken',
    nav4_6: 'TrustWallet',
    nav4_7: 'MetaMask',
    nav5: 'WIKI',
    nav6: '购买DBC',
    nav7: '社区',
    nav8: 'DBC浏览器'
  },
  home: {
    cont1: {
      text1: '欢迎来到',
      text2: 'DeepBrain Chain',
      text3: '深脑链是可以无限扩容的去中心化的高性能GPU算力网络，目标是成为AI+元宇宙时代在全球使用最广泛的GPU算力基础设施',
      text4: '深脑链成立于2017年，深脑链基金会和深脑链理事会两个组织共同推动DBC的发展',
      text5: 'DBC区块链主网于2021年5月20号上线，DBC的GPU算力主网于2021年11月22日正式上线'
    },
    cont2: {
      title1: '华为、深脑链、Polygon一起成立元宇宙和Web3联盟',
      text1: '查看视频',
      title2: 'DBC GPU算力主网已经上线',
      text2: '主网GPU算力信息',
      title3: 'AI算力网络',
      text3: 'DBC Pitch Deck',

      title4: 'DeepLink Public Sale 正在',
      title4_1: '进行中',
      text4: '立即加入'
    },
    cont3: {
      title: 'DBC应用生态',
      text: '任何人都可以基于DBC构建自己的GPU云服务平台',
      title1: 'AI 训练',
      text1: '人工智能训练是指使用大量的数据和算法来训练神经网络，训练的目的是获得一个可以进行预测的模型，也就是神经网络的权重和参数。预计到2024年AI训练的GPU服务器市场规模将达到120亿美元，未来5年，年复合增长率为25%',
      btn1_1: 'Haibao Cloud',
      btn1_2: 'Hycons Cloud',
      title2: 'AI 推理',
      text2: '人工智能推理是指使用训练好的神经网络来对新的数据进行分析和预测，推理的目的是利用训练好的模型，使用新数据推理出各种结论，也就是神经网络的输出和结果。预计到2024年AI推理的GPU服务器市场规模将达到80亿美元，未来5年，年复合增长率为35%',
      title3: '云游戏',
      text3: '云游戏服务允许游戏通过云端GPU服务器来渲染和处理，然后将游戏画面流式传输到玩家的设备上，云游戏允许任何3A游戏在任何设备运行。云游戏市场正在快速增长，估计到2030年市场规模将达到209.3亿美元，复合年增长率为45.5%​',
      btn3_1: 'DeepLink',
      title4: '视觉渲染',
      text4: '视觉渲染解决方案主要应用在电影和3D动画领域，全球市场在2023年的规模为7.237亿美元，并预计将以17.3%的年复合增长率迅速增长，到2033年将达到35.7亿美元',
      btn4_1: 'Hycons Cloud',
      title5: '云网吧',
      text5: '云网吧是一种基于云计算技术的新型网吧服务。在云网吧中，游戏和应用程序实际上运行在远程GPU服务器上，然后通过互联网实时传输到网吧的电脑上。网吧运营者则无需投资高性能GPU硬件的电脑，从而大幅节省硬件投资成本，截止到2023年全球网吧数量超过20万家，总共电脑数量1000万台，云网吧当前市场占有率不到1%，未来5年，每年的市场增速超过100%',
      btn5_1: 'ZestCloud',
      btn5_2: 'Tikeren Cloud',
      title6: 'ZK挖矿',
      text6: 'Filecoin, Aleo, ETH L2层ZK计算等等，需要GPU服务器进行零知识证明计算'
    },
    cont4: {
      title: 'DBC Today',
      text: '最新网络统计数据',
      text1: 'GPU数量',
      text2: 'GPU租用率',
      text3: 'GPU质押DBC数量',
      text4: 'GPU租金销毁DBC数量',
      text5: 'DBC发行总数',
      text6: 'DBC Holders地址数量',
      text7: 'Staking质押DBC数量',
      text8: '理事会DAO国库资金'
    },
    cont5: {
      title: '为什么选择我们？',
      text: '任何人都可以基于DBC构建自己的GPU云服务平台',
      block1: {
        title: '隐私保护',
        text: '保护使用者的隐私通过钱包地址隐藏用户信息',
      },
      block2: {
        title: '低费用',
        text: '相对AWS节省70%的GPU租用费用',
      },
      block3: {
        title: 'Powerful API',
        text: '我们强大的API可实现无缝集成和定制，让您能够灵活控制GPU出租和租用',
      },
      block4: {
        title: '获得奖励',
        text: '基于DBC构建自己的云GPU平台可以从DBC理事会国库申请资金，获得扶持',
      },
      block5: {
        title: '开源且无许可',
        text: '任何云平台都可以基于DBC构建自己的GPU云服务平台',
        text1: '服务自己特定领域的客户且无需许可',
      },
      block6: {
        title: '无限扩容',
        text: '云平台基于无限扩容的算力网络，可以服务大型企业客户，无需担心GPU供应不足',
      }
    },
    cont6: {
      title: 'DBC Token的价值',
      block1: { 
        text: 'DBC总数100亿，总量有限，永远不增发，总共大约100年全部发行',
      },
      block2: {
        text: 'DBC Token是DBC网络中的唯一的Token',
        text1: '每次用户租用GPU都需要从在交易所或者其他途径购买DBC Token，然后支付一定数量的DBC到DeepBrainChain的区块链网络中获取GPU的使用权'
      },
      block3: {
        text: 'DBC是通缩模型，当DeepBrainChain全网的GPU数量在5000个以内用户租金销毁30％,超过5000个用户租金销毁70％,超过1万个租金100％销毁。',
        text1: '用户支付的DBC是需要用户在交易所或者其他途径购买，当用户每租用一次GPU，市场流通的DBC数量就会减少'
      },
      block4: {
        text: 'DBC POS超级节点需要质押DBC进行出块奖励，目前全网质押的DBC数量是{stakeNum}，占到发行DBC总数的{ratio}%.',
      },
      block5: {
        text: '矿工提供GPU需要质押DBC，每卡质押DBC的10万DBC或者最高等值800美金的DBC，意味着DeepBrainChain网络中的GPU数量越多，质押的DBC越多，目前全网GPU矿工质押的DBC数量{stakeNum}，占到发行DBC总数的{ratio}%',
      },
      block6: {
        text: 'DBC Token是DeepBrainChain的治理Token',
        text1: '理事会DAO组织每4个月选择一次，共从所有候选人中选举21个理事会成员',
        text2: '候选人的DBC Token投票数量排在前21位可以当选',
        text3: '每个DBC Token等于一票',
        text4: '理事会DAO组织共同管理国库资金，用来支持DeepBrainChain的生态发展',
      }
    },
    cont7: {
      title: '在哪可以交易DBC',
      text1: ' DBC精度：15，Token符号：DBC，ERC20 DBC合约地址:',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: '代币经济模型',
      text1: '当前DBC GPU算力每日奖励币发行量: 1,095,900枚，DBC主网POS节点每天产出：273,900枚',
      table: {
        tr1: '用法',
        tr2: '分类',
        tr3: '小计',
        tr4: '数量(亿)',
        tr5: '已流通(亿)',
        tr6: '待释放(亿)',
        tr7: '备注',
        tbody1: {
          text1: '早期发售',
          text2: '面向专业投资人或者人工智能厂商众售DBC生态服务使用权',
        },
        tbody2: {
          text: '基金会和生态系统',
          text1: '基金会',
          text2: '2018-2021合计4年解锁10亿枚(每年2.5亿枚)剩余分15年解锁，每年解锁0.49亿枚',
        },
        tbody3: {
          text1: '理事会',
          text2: '理事会全部由社区选举产生，DBC完全由理事会管理，分15年解锁，每年解锁0.51亿枚，从2022年1月开始',
        },
        tbody4: {
          text1: '团队',
          text2: '上交易市场后其中10%锁定1个月，剩余每年解锁10%，总共锁定10年，从2018年开始',
        },
        tbody5: {
          text1: '主网上线前算力奖励',
          text2: '用于激励算工在主网上线前加入GPU到DBC算力网络中',
        },
        tbody6: {
          text: '节点奖励',
          text1: '超级节点',
          text2: '超级节点通过记账出块获得奖励，前3年每年奖励1亿枚，然后5年每年奖励0.5亿枚，以后每5年奖励降低一半，奖励从2021年5月开始',
        },
        tbody7: {
          text1: '算力节点',
          text2: '算力节点通过贡献算力获得在线奖励，前3年每年奖励4亿枚，然后5年每年奖励2亿枚，以后每5年奖励降低一半，奖励从2021年8月开始',
        },
        tbody8: {
          text1: '合计',
        }
      }
    },
    cont9: {
      title: '投资机构及合作伙伴'
    },
    cont10: {
      title: '发展历程&路线图'
    },
    cont12: {
      title: '媒体报道'
    },
    cont13: {
      title: '社区活动'
    }
  },
  buyDBC: {
    title: '购买DBC',
    buy: '购买',
    see: '查看',
    block1: {
      title: '中心化交易所购买DBC'
    },
    block2: {
      title: '去中心化交易所购买DBC'
    },
    block3: {
      title: '闪对平台购买DBC',
      text: '通过自己的钱包可以直接闪对'
    },
    block4: {
      title: '第三方云平台购买DBC',
    }
  },
  buyGpuServer: {
    title: '使用DBC Token可以购买GPU云服务',
    text1: '深脑链是可以无限扩容的去中心化的高性能GPU算力网络，有来自全球的提供者把GPU服务器加入到DBC网络中，并且质押10万或者800USD等值的DBC，从而保证GPU服务器的稳定性和可用性。第三方开发者可以基于DBC开发自己的云平台给用户提供服务，开发者只需要服务用户，无需自己采购GPU服务器，所有的GPU服务器从DBC网络中可以自动获取到。',
    text2: '用户可以在云平台用DBC租用GPU，也可以使用法币租用GPU,如果使用法币租用GPU，则云平台会自动将法币兑换成DBC，租用深脑链中的GPU。',
  
    text3: 'DBC生态应用服务'
  },
  getReward: {
    title: '部署POS出块节点获得奖励',
    text: 'DBC是POS的共识机制，当前一共有41个共识出块节点，每24小时会从所有节点中选举41个节点作为出块节点，被选中的节点，每24小时可以产生27.25万的DBC奖励。选举规则：节点自己质押的DBC以及被投票的DBC总数排名前41位',
    seedetails: '查看详情',
    cont: {
      title: '成为 DBC 验证节点'
    },
    procedure1: {
      title1: '机器配置',
      text1: '内存：8G',
      text2: 'CPU：2 核',
      text3: '硬盘：100G',
    },
    procedure2: {
      title1: '生成资金账户（已有资金账户可以略过）',
      text1: '参考：',
      text2: '如何生成账户'
    },
    procedure3: {
      title1: '获取 dbc-chain 二进制',
      text1: '方式 1: 使用预编译版本',
      text2: '如果不能正常运行，请使用方式 2 自行编译：',
      text3: '方式 2：从源码进行编译',
      text4: '# 安装依赖',
      text5: '# 编译dbc-chain'
    },
    procedure4: {
      title1: '运行同步节点',
      text1: '如果你是从源码进行编译，可执行文件路径为：',
      text2: '同步完成后，Control + C关掉程序。你可以根据target与best的比较来判断是否同步已经完成。当 target 与 best 相差不大（如 100 以内）时，可以认为已经完成同步。',
      text3: '参数说明：',
      text4: '--base-path：指定该区块链存储数据的目录。如果不指定，将使用默认路径。如果目录不存在，将会为你自动创建。如果该目录已经有了区块链数据，将会报错，这时应该选择不同的目录或清除该目录内容',
      text5: '--pruning=archive：以归档的方式启动区块链',
      text6: '--bootnodes：指定引导节点地址。',
    },
    procedure5: {
      title1: '以验证人的方式运行节点',
      text1: '在第 3 步同步节点数据完成之后，关闭同步命令。然后以验证人的方式运行节点：',
      text2: '如果你是从源码进行编译，可执行文件路径为：',
      text3: '注意，这里 --name YourNodeName 是设置你的节点名称，你可以为你的节点起一个独一无二容易辨认的名称，别人将能在网络上看到它。',
      text4: '生成rotateKey',
      text5: '在运行验证人节点命令的机器上运行下面命令'
    },
    procedure6: {
      title1: '设置绑定（bond）金额',
      text1: '打开',
      text2: '你将能看到你的余额：',
      text3: '导航到网络 >质押 > 账户操作 > 存储账户，',
      text4: '设置 bond 的金额（确保除了 bond 的数额，您的账户中还有余额以用来发送交易）：',
      text5: '说明：',
      text6: 'Stash account：你的资金账户，这里我们 bond 45 DBC，确保账户中余额至少有这么多',
      text7: 'controller account：这个账户也应该有少量的 DBC 来发送开始和停止验证人的交易',
      text8: 'value bonded：你想要 bond/stake 多少 DBC, 请注意，你不需要 bond 账户中所有的余额，另外你随后可以增加 bond 的数额。',
      text9: 'payment destination：验证人获得的奖励将会被发给这个账户。这个账户可以设置成任何账户。其中，',
      text10: 'Stash account(increase the amount at stake): 收益发放到 stash 账户，并质押',
      text11: 'Stash account(do not increase the amount at stake)：收益发放到 stash 账户，不进行质押',
    }
  },
  voteReward: {
    title: '给POS节点投票获得奖励',
    text: '每个超级节点，每天有6700左右DBC的奖励，如果投票的节点被选中成为超级节点，则投票人也可以获得奖励，获得奖励的数量为（1-投票人设置的佣金比例）*我的投票数量/总投票数量*27.25万，比如我投票的超级节点总投票数量2000万DBC，我投票100万DBC，节点的佣金比例是10%，则我每天的奖励数量为90%*100万/2000万*6700=301个，一年大概可以获得11万DBC的奖励',
    seedetails: '查看详情',
    cont: {
      title: '成为 DBC 提名人'
    },
    procedure1: {
      title1: '生成资金账户（已有资金账户可以略过）',
      text1: '参考：',
      text2: '如何生成账户'
    },
    procedure2: {
      title1: '获取一些的 DBC',
      text1: '打开',
      text2: '你将能看到你的余额：'
    },
    procedure3: {
      title1: '设置绑定（bond）金额',
      text1: '导航到网络 >质押 > 账户操作 > 存储账户，',
      text2: '在弹窗中选择存储账户(stash account)，控制账户(controller account)，并填写绑定的金额(value bonded)，点击下一步',
    }
  },
  gpuMiner:{
    title: '成为GPU矿工获得奖励',
    text: '在DBC生态中，矿工是非常重要的角色，矿工提供GPU服务器，GPU服务器是DBC网络的基石，当前每天有109万个DBC奖励给GPU提供者。矿工除掉有奖励收益还有租金收益，5000个GPU以内得到租金70％，超过5000卡不到1万卡得到用户租金30%，超过1万卡没有用户租金，但是任何时候机器被租用奖励收益增加30%，每个GPU需要质押10万个DBC或者等值800USD的DBC,两者取其最小值',
    seedetails: '查看详细规则',
    cont: {
      title: 'GPU机器每月收益计算公式',
      text: '机器每月收益计算公式：整机算力值/全网总算力值*1,095,890*DBC价格*地域系数*大模型系数*1.3（被租用算力值增加30%）*30+整机算力值*地域系数*大模型系数*0.508*70%',
      text1: '机器型号：',
      text2: '显卡数量：',
      text3: '内存数量：',
      text4: '地域：',
      text5: '请选择',
      text6: '当前每月收益为:',
      text7: 'USD等值DBC = ',
      text8: '链上奖励收益:',
      text9: 'DBC(等值',
      text10: 'USD 当前DBC价格:',
      text11: 'USD)+租金收益: ',
      text12: 'USD等值DBC'
    },
    country: {
      country1: '中国',
      country2: '北美',
      country3: '南美',
      country4: '欧洲',
      country5: '中东',
      country6: '韩国',
      country7: '日本',
      country8: '中国台湾',
      country9: '中国香港和澳门',
      country10: '马来西亚',
      country11: '印尼',
      country12: '新加坡',
      country13: '越南'
    }
  },
  applyCouncil: {
    title: '申请成为理事会成员',
    text: 'DBC理事会是DBC DAO组织，目前管理着DBC国库总共8亿多的DBC Token，并且每个月有425万DBC进入国库，一直持续到2036年理事会一共由21位成员，每4个月选举一次，得票数前21位的候选人会被选中。',
    text1: '成为理事会成员，可以对国库资金申请提案进行投票，任何提案需要21位理事中的13位投票赞同才能通过，如果投票数不超过13位，首席理事可以代表未投票理事进行投票',
    text2: '成为理事会成员，更可能获得社区和其他理事的信任，当理事自己申请国库资金提案时，更容易获得通过',
    text3: '任何社区成员都可以发起资金申请提案，但是提案进入投票流程，需要21位中的其中一位理事进行提交投票',
    seedetails1: '理事会会议讨论组',
    seedetails2: '查看理事会信息',
    cont: {
      title: '理事会参选流程'
    },
    procedure1: {
      title1: '基于波卡的 DBC 主网钱包',
      text1: '首先，大家得有一个基于波卡的 DBC 主网钱包。建好钱包后打开 DBC 主网地址',
      text2: '点选“治理”一栏。',
      text3: '弹出栏会有“议会”这一选项',
      text3_1: '点进去，就是当前议会竞选状态概览',
      text4: '“座位” — — 当前被选上的理事会成员',
      text5: '“runners up” — — 暂时被刷下来的参选者（亚军）',
      text6: '“候选人” — — 提交了候选人后暂时没进入前 20 的参选者',
      text7: '页面右上角分别是“投票”和“提交候选人” ',
      text8: '如果你想竞选理事会则进入“提交候选人”；如果只想投票就进入“投票”'
    },
    procedure2: {
      title1: '接下来我们先看“投票”',
      text1: '点进去可以看到自己账户余额',
      text2: '选好心仪的候选人后，在“投票值”处输入自己想投票的数量',
      text3: ' 1 个 DBC 算 1 票，注意，少于 1 个 DBC 的投票是不会成功的',
      text4: '确定好后签名提交、输密码确认就可以了 ',
    }
  },
  councilVoting: {
    title: '给理事会候候选人投票',
    text: 'DBC理事会会是管理DBC国库的DAO组织，理事会成员对于DBC的生态的发展至关重要，每一个持有DBC人都应该重视并参与理事会投票，给自己认可的理事会候选人投上神圣的一票，只有获得总票数排名前21位的候选人才能当选理事',
    seedetails1: '理事会会议讨论组',
    seedetails2: '查看理事会信息',
    cont: {
      title: '如何投票？'
    },
    procedure1: {
      title1: '打开理事会投票页面',
      text1: '打开理事会投票页面，',
      text2: '，点击投票（先要登录钱包）',
      text3: '设置投票值',
      text4: '设置投票值，点击理事会候选人，就会把候选人放在右边框中，如果选择多个候选人，多个候选人平均分配票数。最后点击投票。注意：投票过后，投票的币将会被质押，无法转账，只有取消质押才能进行转账操作。建议新一届理事会选举结束的前一天投票，这样币的质押时间最短',
      text5: '下图的红框部分为距离新一届理事会选举结束的时间',
      text6: '取消投票',
      text7: '解除投票的质押币，任何时候都可以取消投票，当点击全部取消按钮后，则取消质押。但是投取消投票后，则投票被取消，建议新一届理事会选举结束后取消投票',
    }
  },
  referenda: {
    title: '参与公投',
    text: 'DBC区块链网络的任何技术升级和参数修改都需要通过公投来完成，公投是DBC社区治理的核心环节，每个DBC持有者关注DBC的技术发展和产品发展，可以关注公投，并参与到公投。也可以对DBC技术发展做出共享，主动发起提案进行公投',
    seedetails: '参看公有提案',
    cont: {
      title: '如何参与 DBC 主网公投投票'
    },
    procedure1: {
      title1: '【治理】-【民主权利】界面功能介绍',
      text1: '提案 — 公众发起的提案。每个启动期结束时（目前为每 28 天），收到附议数最多（指附议的 dbc 总额最多）的提案，会进入全民公投。',
      text2: '外部 — 来自理事会的提案。',
      text3: '启动期 — 新公投开启的周期，目前为 28 天。',
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    text1: '深脑链是可以无限扩容的去中心化的高性能GPU算力网络，目标是成为AI+元宇宙时代在全球使用最广泛的GPU算力基础设施',
    text2: '深脑链成立于2017年，深脑链基金会和深脑链理事会两个组织共同推动DBC的发展',
    text3: 'DBC区块链主网于2021年5月20号上线，DBC的GPU算力主网于2021年11月22日正式上线',
    text4: 'DBC通过区块链的奖励机制聚集来自全球的GPU提供者给DBC网络提供GPU服务器，然后通过DBC分布式网络技术，给用户提供GPU的使用，但是DBC不直接面向用户，而是通过DBC的应用生态给用户提供服务，目前DBC在AI训练、AI推理、云游戏、云网吧、ZK挖矿这些领域都有生态应用给用户提供服务',
    text5: '生态应用越多，生态应用本身的用户对GPU的需求越大，每日使用DBC交易的数量就越多，DBC销毁数量就越多，DBC的价值就越大。',
    text6: '比如以云网吧应用举例：云网吧用户需要在交易市场买币使用GPU，每多使用一个GPU，就会把从交易市场买的币销毁30％。如果有1000个云网吧，每个网吧100台机器，每天每台机器用10个小时，每小时支付0.1美金，其中销毁30％，每个月要销毁90万美金的币，按照0.002USDT的币价一个月要销毁4个多亿的币，同时支撑1000个网吧，需要7万台机器，另外需要质押70亿的币。'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU，全称为图形处理单元（Graphics Processing Unit），是一种专门设计用于处理图形和视频相关任务的计算单元。与CPU（中央处理单元）不同，GPU专门为并行处理大量数据而设计',
    block1: {
      title: '高并行性能',
      text: 'GPU由成百上千个小核心组成，这使得它能够同时处理大量数据。例如，在渲染3D图形时，每个核心可以独立处理一个像素或一个顶点，从而大幅提高处理速度。',
    },
    block2: {
      title: '专为图形优化',
      text: 'GPU最初是为了加速图形渲染而设计的，它能够高效处理与图像和视频相关的计算任务，如纹理映射、光照计算等。'
    },
    block3: {
      title: '广泛的应用',
      text: '虽然GPU最初是为游戏和专业图形设计而设计的，但现在它们在许多其他领域也非常重要，特别是在人工智能和机器学习中。',
    },
    title3: '游戏和人工智能',
    title4: '为什么需要GPU？',
    text2: 'GPU的高并行处理能力使其在处理图形密集型任务和大规模数据处理任务时表现出色，因此在游戏和人工智能领域中变得不可或缺。',
    text3: '目前GPU芯片的领导者NVIDIA市值超过1万亿美金，是CPU芯片领导者Intel市值的6倍，说明GPU的需求巨大，远远大于CPU的需求',
    block4: {
      title: '游戏',
      text: '游戏和现代游戏通常包含复杂的3D图形和物理模拟。这些任务需要大量并行处理，因此非常适合GPU的强大图形处理能力。使用GPU可以实现更平滑的游戏体验和更高的画面质量。',
    },
    block5: {
      title: '人工智能和机器学习',
      text: '在人工智能领域，特别是在深度学习中，需要处理大量数据和进行复杂的数学计算。这些计算任务通常可以并行化，因此非常适合GPU的高并行性能。使用GPU可以显著加快模型训练和推理的速度。'
    }
  },
  historyDBC: {
    title: 'DeepBrainChain发展历程',
    title1: '2017年',
    text1_1: '深脑链项目立项，确定目标和愿景以及技术架构方向',
    text1_2: '募资完成',
    title2: '2018年',
    text2_1: 'DBC Token上线huobi交易所',
    text2_2: 'DBC算力网络上线并且代码在GitHub开源',
    title3: '2020年',
    text3_1: 'DBChain全球AI开发者用户突破10000人，服务全球人工智能相关高校和实验室超过500所',
    title4: '2021年',
    text4_1: '深脑链节点主网正式上线',
    text4_2: '深脑链主网浏览器上线',
    text4_3: '基于深脑链的GPU云平台数量超过50家',
    text4_4: '深脑链GPU算力主网开始公测',
    text4_5: '公测网络GPU算力总数超过1200张卡，租用率超过98%',
    text4_6: '深脑链GPU算力主网已经正式上线',
    title5: '2022年',
    text5_1: '银河竞赛GPU数量达到1500个',
    text5_2: '基于dbc的云平台 haibaogpu 云平台上线，为人工智能用户提供gpu服务',
    text5_3: '韩国3个算池 DBC KOREA HILLSTONE，DBC KOREA Ai-Factory、Gines Korea Center-1加入到DBC网络中',
    text5_4: '基于dbc的韩国GPU云平台 hycons 上线，为AI和云游戏客户提供gpu服务',
    text5_5: '基于dbc的云游戏平台DeepLink上线，以远程控制工具为入口，打造云游戏平台',
    title6: '2023年',
    text6_1: '华为、深脑链、Polygon一起成立元宇宙和Web3联盟',
    text6_2: '全球第一个基于DBC和DeepLink技术的ZestCloud云网吧在韩国首尔试运营',
    text6_3: '全球第一个基于DBC和DeepLink技术的云游戏一体机TIKEREN在日本东京发布，并举行联合发布会',

    title7: '2024年',
    text7_1: 'Q1',
    text7_2: '1.开发GPU短租模式',
    text7_3: '2.支持新特性的主网上线',
    text7_4: 'Q2',
    text7_5: '1.开发支持智能合约功能',
    text7_6: '2.完善GPU短租模式',
    text7_7: '3.支持GameFi游戏转换为云GameFi',
    text7_8: 'Q3',
    text7_9: '1.支持去中心化AIGC项目基于DBC开发智能合约',
    text7_10: '2.支持去中心化AIGC项目基于DBC GPU进行挖矿',
    text7_11: '3.测试完成智能合约功能开发',
    text7_12: 'Q4',
    text7_13: '1.支持3A GameFi基于DBC GPU进行挖矿',
    text7_14: '2.开发DBCSwap功能，支持DBC生态中的代币可以和DBC Token在链上交易'
  },
  introDBC: {
    title: 'DBC DAO自治 简介',
    text1: '随着2021年DBC主网上线，目前有两个组织共同推动DBC的发展，分别是DBC基金会和DBC理事会',
    text2: 'DBC基金会是DBC的创始组织，主要负责DBC的技术开发以及全球布道推广DBC。深脑链基金会和深脑链理事会是两个完全独立的组织，没有任何隶属关系，共同服务深脑链生态，推动深脑链的发展',
    cont2: {
      block1: {
        title: 'DBC理事会',
        text1: '由社区选举的DAO组织，主要负责管理国库资金，扶持DBC生态应用，发展DBC社区，进行DBC的治理',
        text2: '目前管理着DBC国库总共8亿多的DBC Token，并且每个月有425万DBC进入国库，一直持续到2036年',
        text3: '理事会一共由21位成员，每4个月选举一次，得票数前21位的候选人会被选中。',
      },
      block2: {
        title: '成为理事会成员',
        text1: '成为理事会成员，可以对国库资金申请提案进行投票，任何提案需要21位理事中的13位投票赞同才能通过，如果投票数不超过13位，首席理事可以代表未投票理事进行投票。',
        text2: '成为理事会成员，更可能获得社区和其他理事的信任，当理事自己申请国库资金提案时，更容易获得通过',
        text3: '任何社区成员都可以发起资金申请提案，但是提案进入投票流程，需要21位中的其中一位理事进行提交投票',
        btn: '查看详情' 
      }
    },
    cont3: {
      title: 'DBC 资金池资金用途',
      text1: '基础设施部署',
      text1_2: '和持续运营',
      text2: '网络安全运营',
      text2_1: '监查服务、持续审计',
      text3: '生态支持',
      text3_1: '和友好链的合作',
      text4: '市场活动',
      text4_1: '广告、支付、合作',
      text5: '社区活动和外展活动',
      text5_1: 'meetup，pizza parties，hackerspaces',
      text6: '基础设施部署和持续运营',
      text6_1: '钱包和钱包一体化，客户端和客户端升级',
      text7: '上线交易所',
      text8: '更多...'
    },
    cont4: {
      title: '本届前三理事会DAO成员',
      job: '首席理事',
      reward: '得票：'
    },
    cont5: {
      title: '新一届理事选举倒计时 ',
      text1: '提案',
      text2: '正在进行',
      text3: '已通过',
      text3_1: '未通过',
      text4: '申请金额',
      text5: '申请人：',
      text6: '结束时间',
      text7: '小时',
      text8: '已经结束时间',
      text9: '赞同',
      text10: '反对',
      text11: '天'
    },
    seemore: '查看更多'
  },
  aboutTeam: {
    title: '关于团队',
    lead: '何永',
    job: '创始人',
    intro : {
      text1: 'DeepBrainChain 和DeepLink 创始人 何永，连续创业者',
      text2: '14年AI、7年区块链和分布式算力网络技术开发经验，2008年本科毕业于中国海洋大学计算机专业，2010年华东师范大学生物信息学，硕博连读肄业',
      text3: '2012年创立聚熵智能，研发了全球第一款中文AI语音助手——智能360，3年时间软件下载用户量超过1700万',
      text4: '2014年推出来全球第一款人工智能音箱——小智超级音箱',
      text5: '2017年5月创立DeepBrain Chain，目前服务了上百家人工智能、云游戏、云网吧客户',
      text6: '2020年创立了DeepLink，DeepLink Protocol是去中心化AI云游戏协议,协同DeepBrain Chain一起服务全球云网吧和3A游戏公司'
    },
    application: {
      title: '职位申请',
      desc: 'DeepBrainChain基金会目前研发以及市场全职&兼职人员超过30人，人员覆盖国家有新加坡、中国、韩国、中国台湾、日本、印度、越南、孟加拉国、尼日尼亚等国家'
    },
    weekly: '最近16期DBC双周报',
    see: '查看详情',
    seemore: '查看更多'
  },
  deployCloud: {
    title: '租用GPU的Powerful API',
    step1: {
      text1: '链上租用GPU API接口',
      text2: 'API节点地址：xxxx，也可以自己部署区块链API同步节点,部署文档：',
      text3: '租用链上机器',
      text4: '确认可用并租用',
      text5: '续租',
    },
    step2: {
      text1: '租用GPU整机',
      text2: '创建虚拟机',
      text3: '重启虚拟机',
      text4: '确认可用并租用',
      text5: '续租',
    },
    step3: {
      text1: '租用GPU裸金属机器',
      text2: '创建机器',
      text3: '重启机器',
      text4: '关机',
      text5: '获取DeepLink设备ID和验证码',
      text6: '获取Ubuntu账户和密码'
    },
    server: '有任何疑问可以联系：'
  },
  cloudInternet: {
    title: '如何成为云网吧解决方案商',
    text: '目前全球主要网吧分布国家有韩国（6000家），中国（4万家），巴西（1万家），（印尼）2万家、菲律宾（2万家）、越南（1.5万家），土耳其（5000家），还有印度、墨西哥、中东、南美、非洲、欧美等等国家，全球一共加起来网吧数量超过15万家，但是网吧行业已经有20年的历史，已经变成一个传统行业，回本周期已经上升到3-4年时间，而且面临着各种风险，比如新冠疫情曾让大量网吧倒闭，所以网吧行业需要一种全新的解决方案，可以降低回本时间，降低风险，提高运营网吧的效率，云网吧解决方案由此诞生',
    cont2: {
      text1: '什么是',
      text2: '云网吧解决方案？',
      text3: '云网吧是一种新型的网吧服务模式，拥有超低延迟渲染技术。在云网吧中，游戏和应用程序不是直接安装在本地电脑上，而是托管在云端GPU服务器上。用户通过云端服务访问和玩游戏，而不需要高性能的本地硬件。',
      block1: {
        text1: '减少本地硬件需求',
        text2: '由于游戏和应用程序运行在云端GPU服务器上，因此本地电脑不需要高性能的GPU或大量存储空间。这降低了网吧的硬件投资和维护成本'
      },
      block2: {
        text1: '即时访问最新游戏',
        text2: '用户可以即时访问最新的游戏，无需等待下载和安装。所有游戏更新和维护都在云端进行，为用户提供了便利和高效的体验'
      },
      block3: {
        text1: '高性能游戏体验',
        text2: '本地显示器只需要配置mini pc（100美金左右），云网吧也能提供高性能的游戏体验。因为游戏的运算工作是在远程高性能GPU服务器上完成的'
      },
      block4: {
        text1: '节省空间和能源',
        text2: '较低配置的本地电脑占用更少的空间，并且消耗更少的电力，有助于降低网吧的运营成本'
      },
      block5: {
        text1: '易于管理和维护',
        text2: '网吧管理者可以更容易地管理软件和游戏，因为所有的内容都在云端集中管理。这减少了本地维护工作和潜在的技术问题'
      }
    },
    cont3: {
      text1: '韩国Glowsteam云网吧',
      text2: '云网吧实际运行环境'
    },
    cont4: {
      text1: '云网吧方案4年时间可以帮助网吧投资者相对传统网吧方案',
      text2: '提升3倍的净利润，回本周期缩短40%',
      text3: '点击下载对比数据'
    },
    cont5: {
      text1: '云网吧解决方案商的收益如何？',
      text2: '如果按照每个网吧机器的收益12%作为收益，则服务1000个网吧总共100000台机器，每个机器平均每天使用10个小时，每个网吧机器平均费用是0.8美金，则12%约等于0.1美金',
      text3: '云网吧解决方案商一年的收益总和为',
      text4: '100000*10*0.1*365=3650万美金',
      text5: '如何成为云网吧解决方案商',
      text6: '韩国HYCONS基于DeepBrainChain和DeepLink技术开发了一套完整的云网吧解决方案，其中DeepBrainChain提供GPU算力，DeepLink提供超低延迟渲染技术，HYCONS提供完整解决方案（如果类比一辆车，DeepBrainChain提供发动机，DeepLink提供无人驾驶系统，HYCONS提供完整的车解决方案，品牌商只需要换个车的颜色贴一个自己的品牌就可以售卖），各个国家本地方案商只需要在HYCONS基础上接入本地支付系统就可以给本地网吧提供服务，并且方案商可以设置自己的品牌，HYCONS只作为技术服务商不暴露品牌',
      text7: '具体可以联系 ：',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: '云网吧相关资料下载',
      text1: 'HYCONS介绍',
      text2: '云网吧方案收益分析',
      text3: '云网吧数据中心架构图',
      text4: '云网吧方案测试安装手册',
      text5: 'DeepLInk协议介绍',
    }
  },
  gameAgents: {
    title: '如何成为云游戏一体机代理商',
    text: '什么是云游戏一体机？云游戏一体机相比较传统一体机显示器是高FPS刷新率，达到流程的游戏体验。HKC集团的TIKEREN云游戏一体机是基于DeepBrainChain+DeepLink的技术研发的，可以实时连接远程GPU高性能算力进行游戏渲染和计算。',
    title1: 'TIKEREN一体机展示',
    table: {
      th: {
        text1: '排序',
        text2: '项目',
        text3: '27 英寸',
        text4: '31.5 英寸',
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27 英寸',
        text3: '31.5 英寸'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: '上，下，左，右，90°旋转，升降',
        text3: '上，下，左，右，90°旋转，升降'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: '请邮件联系'
  },
  convertGame: {
    title: '如何将AAA游戏转化为云游戏？',
    text: 'DeepLink Protocol是一个去中心化的超低延迟云游戏渲染协议，任何应用都可以基于DeepLink Protocol开发云游戏相关产品，其中云端的算力由DeepBrainChain提供',
    text1: '了解DeepLink Protocol',
    step: {
      title1: '将游戏接入DeepLink Protocol',
      text1: "联系service{'@'}deeplink.cloud",
      title2: 'DeepLink会自动调用DeepBrainChain GPU服务器',
      text2: '将游戏部署在云端运行'
    }
  },
  applyForFunding: {
    title: '如何申请理事会国库资金',
    text1: '申请资金之前可以加入理事会资金申请Telegram讨论群',
    text2: 'Telegram讨论群',
    cont: {
      title: 'DBC Treasury 资金申请流程',
      text: '当申请人从国库申请就资金时，需要保留提案申请金额 5%的资金。如果国库提案被通过，则该保留的资金会被退还；如果国库提案被拒绝，该保留的资金将被惩罚。请注意，用户无法在提交财务提案后撤销该提案。',
    },
    procedure: {
      text: 'Treasury 资金申请流程：',
      title1: '准备草案',
      text1: '根据提案模板，准备草案，提案模板参考',
      text2: '英文：',
      text3: '中文：',
      title2: '提案链下讨论',
      text4: '在链上提交提案前，提案人可以在链下任何场合与理事会、社区成员进行提案讨论，如微信、telegram 社群等，并整合多方意见，不断完善提案。 目前 DBC 在 reddit 上建立了 subreddit 社区 DBC_Council（https://www.reddit.com/r/DBC_Council/open in new window），中国大陆使用需科学上网。该社区也建立了提案讨论的分区【proposal_draft discussion】。提案人亦可将草案发布至该社区，并添加 flair【proposal_draft discussion】，进行讨论。',
      
      title3: '链上提交',
      text3_1: '在 DBC 主网-governance-treasury 页面（https://www.dbcwallet.io/#/treasury）进行链上提交提案，输入申请的 DBC 总数，然后系统会自动抵押 5%的 DBC 作为保证金。',
      text3_2: '注意！如果提案通过，保证金将退还；如果提案被拒绝，保证金不退回！',
      text3_3: '因此，请重视提案的准备工作，提交较为完整、可行的提案。',
      text3_4: '链上提交的同时，提案人必须将提案内容发布至 subreddit 社区 DBC_Council（https://www.reddit.com/r/DBC_Council/），并添加flair【proposal_submitted onchain】。链上提交后你将获得链上提案的编号和区块高度，将【编号】添加入你的提案帖中，方便链上-链下对应。',
      title4: '理事会提出动议并进行投票',
      text4_1: '当申请人完成申请后，将能在https://www.dbcwallet.io/#/treasury中看到申请。',
      text4_2: '这时，理事会可以对该申请提出动议，接受或拒绝该提案。 接受申请提案的动议需要 3/5 的议会同意；拒绝申请提案的动议需要 1/2 的议会同意。',
      text4_3: '请注意！理事会需要在国库中https://www.dbcwallet.io/#/treasury提出动议才能在议会--动议https://www.dbcwallet.io/#/council/motions 中进行投票。',

      title5: '投票完成',
      text5_1: '在投票周期结束之后，议会需要手动关闭该动议(https://www.dbcwallet.io/#/council/motions)。',
      text5_2: '投票出结果后，DBC subreddit 的社区管理员会降通过的提案归档至 flair【proposal_approved】，未通过的提案归入 flair【proposal_rejected】。',
      title6: '资金发放',
      text6_1: '资金分批发放，请申请成功的提案人持续更新自己的成果。请在提案中写明每阶段需要的资金。提案完成情况由社区成员（非理事会成员）进行监督投票。',
      text6_2: '特别注意：接收资金的地址不能是交易所地址，需要是自己能掌握私钥的地址，最好是 DBC 主网生成的地址，否则通过后无法收到下发的资金。',
      text6_3: '注意：通过的资金申请发放将在花费周期结束时发放。花费周期可以到https://www.dbcwallet.io/#/treasury进行查看(右上角有花费周期倒计时)。'
    },
  },
  createwallet: {
    title: 'DBCWallet钱包',
    text: 'DBCWallet钱包是DBC基金会官方维护的全功能钱包，可以用此钱包创建DBC和DLC钱包地址，可以参与POS投票，可以参与理事会选举投票，参与公投等等',
    text1: '访问网站',
    cont2: {
      title: '钱包地址创建方法',
      text1: '为了保护用户持币安全，采用插件的形式创建钱包',
      step: {
        title1: '安装Google Chrome浏览器',
        title2: '搜索Polkadot插件',
        text2: '安装插件',
        title3: '保存种子',
        text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
        title4: '刷新www.dbcwallet.io',
        text4: '我的账户页面会出现已经创建的钱包'
      },
      step1: {
        title: '钱包地址创建方法',
        text1: '1、首先输入dbcwallet.io',
        text2: '2、进入主网界面后选择“账户”',
        text3: '3、出现添加钱包的提示后点击“polkadot.js”',
        text4: '4、即可自动跳转到插件安装页面',
        text5: '5、安装完成后，浏览器右上方会出现波卡钱包插件符号，点击进入后即可创建或导入钱包',
        text6: '6、创建时请牢记您的钱包种子助记词',
        text7: '7、设置钱包名称和操作密码',
        text8: '8、设置完毕后回到主网页面刷新',
        text9: '9、即可看到您的钱包出现在主网上'
      }
    },
    title1: '钱包创建视频'
  },
  polkaWallet: {
    title: 'PolkaWallet钱包',
    text: 'GooglePlay和AppStore 搜索PolkaWallet，或者访问官网：PolkaWallet.io下载',
    text1: 'PolkaWallet.io下载',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: '创建流程',
      text1: '1、浏览器打开polkawallet.io',
      text2: '2、进入波卡官网下载APP钱包',
      text3: '3、下载后进入选择新建或导入DBC主网钱包',
      text4: '4、选择Substrate账户',
      text5: '5、新建钱包请妥善备份密钥',
      text6: '6、设置好名称与密码即可进入主界面',
      text7: '7、主界面左滑选择DBC',
      text8: '8、图标点进入去即可发送及接收主网DBC',
      text9: '9、收款界面',
      text10: '10、发送转账界面。主网钱包用的gas是DBC，请转账时预留少量DBC',
    }
  },
  mathWallet: {
    title: 'MathWallet钱包',
    text: 'GooglePlay和AppStore 搜索mathWallet，或者访问官网：https://mathwallet.org/下载',
    text1: 'MathWallet下载',
    step: {
      title: '创建流程',
      text1: '1、浏览器输入mathwallet.org',
      text2: '2、进入官网后选择下载路径',
      text3: '3、安卓可直接下载安装包或跳转谷歌商店下载',
      text4: '4、下载完毕点开钱包，需手动选择DBC主网',
      text5: '5、DBC主网钱包在倒数第四页',
      text6: '6、可以创建或导入',
      text7: '7、创建密码需要包含大小写字母',
      text8: '8、请备份好助记词',
      text9: '9、看到DBC图标出现后点进入可以接收和发送资产',
      text10: '10、发送界面',
      text11: '11、左滑可创建更多钱包',
      text12: '12、右下角设置栏可选择语言',
      text13: '13、主界面下方中间可看到主网浏览器'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: '搜索DBC合约地址:',
    text5: '精度：',
  },
  ImToken: {
    title: 'ImToken钱包',
    text: '访问官网：token.im下载',
    text1: 'token.im下载',
    step: {
      title: '创建流程',
      text1: '1、用浏览器输入token.im',
      text2: '2、进入后“立即下载”',
      text3: '3、可选直接安装或谷歌商店安装',
      text4: '4、下载好后进入APP创建钱包',
      text5: '5、创建完成建议立即备份并妥善保管密钥',
      text6: '6、进入钱包主页面后，点“+”号添加代币',
      text7: '7、在搜索栏输入DBC的合约地址：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8、添加成功后返回主页面即可看到DBC图标',
      text9: '9、点图标即可看到DBC的收款地址和发送',
      text10: '10、发送给对方时需注意是填写ERC20的地址',
    }
  },
  trustWallet: {
    title: 'TrustWallet钱包',
    text: 'GooglePlay和AppStore 搜索TrustWallet，或者访问官网：https://trustwallet.com/下载',
    text1: 'TrustWallet下载',
    step: {
      title: '创建流程',
      text1: '1、浏览器输入trustwallet.com',
      text2: '2、进入后下载有三种渠道',
      text3: '3、下载好进入创建新钱包',
      text4: '4、新钱包请立即备份',
      text5: '5、备份完成后进入主界面',
      text6: '6、主界面右上角重叠的小“+”号点进入后，再点当前页面右上角“+”号',
      text7: '7、导入代币界面，粘贴DBC合约地址：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8、导入成功可看到DBC图标',
      text9: '9、点收款即可获取当前DBC钱包地址',
      text10: '10、点付款进入发送界面，ERC20链需要预留ETH作为gas才能发送成功'
    }
  },
  metaMask: {
    title: 'MetaMask钱包',
    text: 'GooglePlay搜索MetaMask，或者访问官网：https://metamask.io/下载',
    text1: 'MetaMask下载',
    step: {
      title: '创建流程',
      text1: '1、浏览器打开metamask.io',
      text2: '2、谷歌商店安装下载',
      text3: '3、下载后导入或新建钱包成功即可进入主界面',
      text4: '4、点界面下方“导入代币”进入添加代币界面',
      text5: '5、选择“自定义代币”并输入DBC合约地址：0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6、导入成功可在主界面看到DBC图标',
      text7: '7、点进入即可看到接收和发送DBC选项',
      text8: '8、发送需要钱包中存有ETH作为gas'
    }
  },
  footer: {
    logo: 'Logo下载',
    server: '服务邮箱'
  },
}